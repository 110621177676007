import React from 'react';
import {Col, Row, List, Avatar} from "antd";
import carltonImage from './../img/carlton.gif';
import screenshot from './../img/screenshot.png';


const Rules = () => {

    const firstColumnData = [
        {
            title: 'Family Member Menu',
            decription: 'Select a family member pair from the menu to see their gift list tables.',
            number: '1'
        },
        {
            title: 'Log In As Someone Else',
            decription: "Everything on this little site runs on who is logged in. This will dictate what you can see and what you can't see and allows you to edit your appropriate table. So here you can log in as the right person!",
            number: '2'
        },
        {
            title: 'Currently Logged In Family Member',
            decription: "Here you can see who you are currently logged in as. Everything on this little site runs on who is logged in. This will dictate what you can see and what you can't see and allows you to edit your appropriate table.",
            number: '3'
        },
        {
            title: 'Name & Link',
            decription: 'Fairly straight forward, but here (if you are looking at your table and you are logged in as yourself) you can edit the name of what you would like for Christmas and where to find it.',
            number: '4'
        },
        {
            title: 'Delete A Row',
            decription: 'Accidentally made an extra row? Wanna just nuke a row and start another one? Go for it. Hit that little trash can.',
            number: '5'
        }
    ];

    const secondColumnData = [
        {
            title: 'Add A Row',
            decription: 'Click this guy to add a row to start giving Santa, I mean your family, your greatest hopes and wishes in life.',
            number: '6'
        },
        {
            title: 'Gift Link',
            decription: "When your edit your gift item and add a url, it will look like this. People won't see the url, just this text that links to the gift. Click away! I promise it won't be a Rick Roll.",
            number: '7'
        },
        {
            title: 'Purchased Checkbox',
            decription: 'Playing Santa for someone and bought them an awesome little ditty? Click this magnificent little square to let everyone else know you are the greatest human being alive.',
            number: '8'
        },
        {
            title: 'Purchased By Label',
            decription: 'If someone has already bought a particular gift, you will see their name here on this column (as well as the checkbox checked).',
            number: '9'
        }
    ];


    return (
        <div id={'rulesContent'} style={{height: '100%'}}>
            <Row justify={'space-around'} align={'middle'} style={{height: '100%'}}>
                <Col span={18}>
                    <div id={'carltonDanceDiv'} style={{textAlign: 'center', paddingBottom: '2.5em'}}>
                        <img src={carltonImage} alt={'carlton-dance'}/>
                    </div>

                    <div id={'introRules'} style={{paddingBottom: '5em'}}>
                        <h4>
                            Hello sweet family! Welcome to our really cool site where we make lists and check them twice 🎅.
                            Below you will find the instructions of how to use this site to switch users, check off items you buy for people, add things to your wishlists, etc.
                            Happy Christmasing! 🌲🎁
                        </h4>

                        <h4>
                            Oh, and if you run into a snafu with the site, it's ususally safe to just reload the page and you should be squared away 😬
                        </h4>
                    </div>

                    <div>
                        <img id={'screenshot'} src={screenshot}/>
                    </div>

                    <div id={'mainRules'}>
                        <Row justify={'center'}>
                            <Col span={12} className={'rulesCols'}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={firstColumnData}
                                    renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={<Avatar style={{background: '#BB2528'}}>{item.number}</Avatar>}
                                                title={item.title}
                                                description={item.decription}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Col>

                            <Col span={12} className={'rulesCols'}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={secondColumnData}
                                    renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={<Avatar style={{background: '#BB2528'}}>{item.number}</Avatar>}
                                                title={item.title}
                                                description={item.decription}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    )
};

export default Rules;