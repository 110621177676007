import {Form, Select} from "antd";
import React from "react";

const FamilySelect = (props: any) => {
    const {label, wrapper: {span, offset}} = props;

    const selectAndVisit = (optionValue: any) => {
        const familyMember = optionValue;

        localStorage.setItem('familyMember', familyMember);
        localStorage.setItem('children', '');

        if(familyMember === 'Mat' || familyMember === 'Miriam') {
            localStorage.setItem('children', 'Aiden+Chloe');
        }else if (familyMember === 'Sara' || familyMember === 'Jonny') {
            localStorage.setItem('children', 'Millie+Moses+Timothy');
        }else if(familyMember === 'Ben' || familyMember === 'Nicole') {
            localStorage.setItem('children', 'Morgan');

        }

        console.log(`${familyMember} has now been set in localStorage, see? --> ${localStorage.getItem('familyMember')}`);

        window.location.href = '/home';
    };

    return (
        <div>
            <Form layout={'vertical'} initialValues={{ layout: 'vertical' }}>
                <Form.Item label={label} wrapperCol={{span: span, offset: offset}}>
                    <Select style={{textAlign: 'center'}}
                        size={'small'}
                        onSelect={(optionValue) => {
                            selectAndVisit(optionValue);
                        }
                    }>
                        <Select.Option value="Mom">Mom</Select.Option>
                        <Select.Option value="Dad">Dad</Select.Option>
                        <Select.Option value="Ben">Ben</Select.Option>
                        <Select.Option value="Nicole">Nicole</Select.Option>
                        <Select.Option value="Sara">Sara</Select.Option>
                        <Select.Option value="Jonny">Jonny</Select.Option>
                        <Select.Option value="Miriam">Miriam</Select.Option>
                        <Select.Option value="Mat">Mat</Select.Option>
                    </Select>
                </Form.Item>
            </Form>
        </div>
    )
};

export default FamilySelect;