import React from 'react';
import { Typography, Row, Col} from 'antd';
import "../snow";
import FamilySelect from "./FamilySelect";

const { Title } = Typography;


const SignIn = () => {
    return (
        <div id={'signIn'}>
            <Row justify={'space-around'} align={'middle'}>
                <Col span={10}>
                    <div id={'signInDisplay'}>
                        <Title id={'signInTitle'}>Longinow Family Christmas</Title>

                        <FamilySelect
                            label={'Sign In Below!'}
                            wrapper={{
                                span: 8,
                                offset: 8
                            }}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    )
};

export default SignIn;