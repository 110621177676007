import React, {useState, useEffect} from 'react';
import {Table, Input, Checkbox, Space, Tag} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';

const {Column, ColumnGroup} = Table;

const axios = require('axios');


const WishlistTable = ({member, refresh}: {member: string, refresh: boolean}) => {
    const loggedInMember = localStorage.getItem('familyMember') || '';
    const memberChildren: string = localStorage.getItem('children') || '';

    const [memberInfo, setMemberInfo] = useState<any[]>([]);

    const [refreshThis, setRefreshThis] = useState(new Date());

    const getRandomNumber = () => {
        return Math.floor(Math.random() * Math.floor(99));
    };

    const refreshPage = () => {
        setRefreshThis(new Date());
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Link',
            dataIndex: 'url',
            key: 'url',
            render: (text: string ) => {
                if (!text) {
                    return null
                }
                return <a target="_blank" href={text}>Check it out!</a>
            }
        },
        {
            title: 'Purchased',
            dataIndex: 'purchased',
            key: 'purchased',
            render: (boolean: boolean, record: any) =>  (
                <Checkbox
                    defaultChecked={boolean}
                    onChange={
                        (e) => {
                            handleCheckbox(e, record, 'purchased');
                        }
                    }>
                </Checkbox>
            )
        },
        {
            title: 'Purchased By',
            dataIndex: 'purchased_by',
            key: 'purchased_by',
            render: (purchasedBy: any) => {
                if(purchasedBy) {
                    return <Tag color={'green'}>{purchasedBy}</Tag>
                }
            }

        }
    ];

    const handleSave = (value: any, record: any, columnName: string) => {
        const requestNumber = getRandomNumber();

        console.log(`[Request #${requestNumber}] using handleSave to request to change ${columnName}`);
        const newRecord = {
            ...record
        };

        newRecord[columnName] = value;

        axios({
            method: 'put',
            url: 'https://project-christmas-backend-al27ly6i0.vercel.app/editFamilyMemberInfo',
            data: newRecord
        }).then((res: any) => {
            console.log(`[Request #${requestNumber}] this is the response from express for the request to change ${columnName} -->`, res);

            refreshPage();
        });
    };

    const handleCheckbox = async (e: any, record:any, columnName: string) => {
        await handleSave(e.target.checked, record, columnName);

        record.purchased = e.target.checked;

        setTimeout(async () => {
            if(e.target.checked === true) {
                await handleSave(loggedInMember, record, 'purchased_by')
            }else if(e.target.checked === false) {
                await handleSave(null, record, 'purchased_by')
            }

            refreshPage();
        }, 500);
    };

    const handleDelete = (record: any) => {
        const requestNumber = getRandomNumber();
        console.log(`[Request #${requestNumber}] using handleDelete to request to delete`);
        axios({
            method: 'delete',
            url: 'https://project-christmas-backend-al27ly6i0.vercel.app/deleteFamilyMemberInfo',
            data: {
                giftIdea_id: record._id
            }
        }).then((res: any) => {
            console.log(`[Request #${requestNumber}] this is the response from express for the handelDelete -->`, res);

            refreshPage();
        });
    };


    const editableColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: any) => {
                return <Input
                    id={'editableName'}
                    onPressEnter={
                        (e) => handleSave(e.currentTarget.value, record, 'name')
                    }
                    onBlur={
                      (e) => handleSave(e.currentTarget.value, record, 'name')
                    }
                    defaultValue={text}
                />
            }
        },
        {
            title: 'Link',
            dataIndex: 'url',
            key: 'url',
            render: (text: string, record: any) => {
                return <Input
                    id={'editableUrl'}
                    onPressEnter={
                        (e) => handleSave(e.currentTarget.value, record, 'url')
                    }
                    onBlur={
                        (e) => handleSave(e.currentTarget.value, record, 'url')
                    }
                    defaultValue={text}
                />
            }
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text:any , record: any) => (
                <Space>
                    <a onClick={() => { handleDelete(record)}}><DeleteOutlined /></a>
                </Space>
            )
        }
    ];

    const getData = async () => {
        const requestNumber = getRandomNumber();

        console.log(`[Request #${requestNumber}] getting data for ${member}`);
        await axios({
            method: 'post',
            url: 'https://project-christmas-backend-al27ly6i0.vercel.app/familyMemberInfo',
            data: {
                name: member
            }
        }).then((res: any) => {
            console.log(`[Request #${requestNumber}] received data for ${member}`, 'and here it is -->', res);
            setMemberInfo(res.data[0].gift_ideas);
        });
    };

    useEffect(() => {
        new Promise<void>((resolve, reject) => {
            setMemberInfo([]);

            console.log('clearing out the memberInfo -->', memberInfo);

            resolve()
        }).then(() => {
            console.log('now we are getting data!');
            getData();
        });
    }, [member]);

    useEffect(() => {
        getData();
    }, [refreshThis, refresh]);

    console.log(`about to hit return for ${member}`, 'and here is their info --> ', memberInfo);

    return (
        <div id={'tableSection'} style={{height: '100%'}}>
            <Table dataSource={memberInfo} pagination={false}>
                <ColumnGroup title={member}>
                    {member === loggedInMember ||  member.includes(loggedInMember) || memberChildren.includes(member) ?
                        editableColumns.map((column) => {
                            const {title, dataIndex, key, render} = column;

                            if(render) {
                                return <Column title={title} dataIndex={dataIndex} key={key} render={render} align={title === 'Delete' || title === 'Purchased' ? 'center' : 'left'}/>
                            }else {
                                return <Column title={title} dataIndex={dataIndex} key={key} align={title === 'Delete' || title === 'Purchased' ? 'center' : 'left'}/>
                            }
                        })
                    :
                        columns.map((column) => {
                            const {title, dataIndex, key, render} = column;

                            if(render) {
                                return <Column title={title} dataIndex={dataIndex} key={key} render={render} align={title === 'Delete' || title === 'Purchased' ? 'center' : 'left'}/>
                            }else {
                                return <Column title={title} dataIndex={dataIndex} key={key} align={title === 'Delete' || title === 'Purchased' ? 'center' : 'left'}/>
                            }
                        })
                    }
                </ColumnGroup>
            </Table>
        </div>
    )
};

export default WishlistTable;