import React, { useState } from "react";
import { Layout, Menu } from "antd";
import { UserOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import FamilySelect from "./FamilySelect";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Rules from "./Rules";
import WishlistSection from "./WishlistSection";

const { Sider, Content } = Layout;

const Home = () => {
    const [toggled, setToggled] = useState(false);

    const greeting = `Hello there, ${localStorage.getItem('familyMember')}!`;

    const highlightName = () => {
        let greeting = document.querySelectorAll('.ant-menu-item-group')[0];

        greeting.innerHTML = `<div class="ant-menu-item-group-title" title="Hello there, ${localStorage.getItem('familyMember')}!">Hello there, <span id="loggedInName">${localStorage.getItem('familyMember')}</span>!</div>`
    };

    window.onload = () => {
        highlightName();
    };

    return (
        <Router>
            <Layout id={'home'}>
                <Sider id={'sideBar'}
                    trigger={toggled?'🌲':'🌲'}
                    collapsible
                    collapsed={toggled}
                    onCollapse={() => setToggled(!toggled)}
                    collapsedWidth={0}
                >
                    <div className="logo" />

                    <Menu
                        theme="dark"
                        mode="inline"
                        defaultSelectedKeys={['1']}
                    >
                        <Menu.ItemGroup className={'greeting'}  title={greeting}> </Menu.ItemGroup>
                        <Menu.ItemGroup className={'secondGreeting'} title={'Merry Christmas 🌟'}> </Menu.ItemGroup>

                        <Menu.Item id={'rules'}>
                            <Link to={"/home"}>Rules</Link>
                        </Menu.Item>

                        <Menu.ItemGroup title={'Family Members'}>
                            <Menu.Item key="mom+dad" icon={<UserOutlined />}>
                                <Link to={"/home/mom+dad"}>Mom & Dad</Link>
                            </Menu.Item>

                            <Menu.Item key="ben+nicole" icon={<UserOutlined />}>
                                <Link to={"/home/ben+nicole"}>Ben & Nicole</Link>
                            </Menu.Item>

                            <Menu.Item key="sara+jonny" icon={<UserOutlined />}>
                                <Link to={"/home/sara+jonny"}>Sara & Jonny</Link>
                            </Menu.Item>

                            <Menu.Item key="mat+miriam" icon={<UserOutlined />}>
                                <Link to={"/home/mat+miriam"}>Mat & Miriam</Link>
                            </Menu.Item>
                        </Menu.ItemGroup>


                        <Menu.ItemGroup className={'reLogLabel'} title={'Log In As Someone Else!'}> </Menu.ItemGroup>

                        <FamilySelect
                            wrapper={{
                                span: 20,
                                offset: 2
                            }}
                        />
                    </Menu>
                </Sider>

                <Layout>
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '24px 16px',
                            padding: 24,
                            minHeight: 280,
                        }}
                    >
                        <Switch>
                            <Route exact path={'/home'}>
                                <Rules />
                            </Route>

                            <Route path={'/home/mom+dad'}>
                                <WishlistSection members={['Mom', 'Dad', 'Mom+Dad']}/>
                            </Route>

                            <Route path={'/home/ben+nicole'}>
                                <WishlistSection members={['Ben', 'Nicole', 'Ben+Nicole', 'Morgan']}/>
                            </Route>

                            <Route path={'/home/sara+jonny'}>
                                <WishlistSection members={['Sara', 'Jonny', 'Sara+Jonny', 'Millie', 'Moses', 'Timothy']}/>
                            </Route>

                            <Route path={'/home/mat+miriam'}>
                                <WishlistSection members={['Mat', 'Miriam', 'Mat+Miriam', 'Aiden', 'Chloe']}/>
                            </Route>
                        </Switch>
                    </Content>
                </Layout>
            </Layout>
        </Router>
    );
};

export default Home;