import React from 'react';
import './App.css';
import SignIn from "./components/SignIn";
import Home from "./components/Home";
import 'antd/dist/antd.css';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";



export default function BasicExample() {
    const getTime = () => {
        const time = new Date();
        const hour = time.getHours();
        const minute = time.getMinutes();
        const seconds = time.getSeconds();

        return `${hour}:${minute}:${seconds}`;
    };

    console.log(`|${getTime()}| Just reloaded the app`);

  return (
      <Router>
          <Switch>
              <div>
                  <Route exact path={"/"}>
                    <SignIn />
                  </Route>

                  <Route exact path={"/home"}>
                      <Home/>
                  </Route>

                  <Route exact path={"/home/mom+dad"}>
                      <Home/>
                  </Route>

                  <Route exact path={"/home/ben+nicole"}>
                      <Home/>
                  </Route>

                  <Route exact path={"/home/sara+jonny"}>
                      <Home/>
                  </Route>

                  <Route exact path={"/home/mat+miriam"}>
                      <Home/>
                  </Route>
              </div>
          </Switch>
      </Router>
  );
}

