import {Col, Row, Button} from "antd";
import React, {useState, useEffect} from "react";
import WishlistTable from "./WishlistTable";
import {PlusSquareOutlined} from '@ant-design/icons';
import axios from 'axios';

const WishlistSection = ({members}: {members: string[]}) => {

    const [member0_id, setMember0_id] = useState([]);
    const [member1_id, setMember1_id] = useState([]);
    const [member2_id, setMember2_id] = useState([]);
    const [memberChildren_id, setMemberChildren_id] = useState([]);
    const [memberChildren2_id, setMemberChildren2_id] = useState([]);
    const [memberChildren3_id, setMemberChildren3_id] = useState([]);

    const [refresh, setRefresh] = useState(false);

    const loggedInMember: string = localStorage.getItem('familyMember') || '';
    const memberChildren: string = localStorage.getItem('children') || '';

    const getMemberIds = async () => {
        await axios({
            method: 'post',
            url: 'https://project-christmas-backend-al27ly6i0.vercel.app/familyMemberInfo',
            data: {
                name: members[0]
            }
        }).then((res: any) => {
            setMember0_id(res.data[0]._id);
        });

        await axios({
            method: 'post',
            url: 'https://project-christmas-backend-al27ly6i0.vercel.app/familyMemberInfo',
            data: {
                name: members[1]
            }
        }).then((res: any) => {
            setMember1_id(res.data[0]._id);
        });

        await axios({
            method: 'post',
            url: 'https://project-christmas-backend-al27ly6i0.vercel.app/familyMemberInfo',
            data: {
                name: members[2]
            }
        }).then((res: any) => {
            setMember2_id(res.data[0]._id);
        });

        if(memberChildren === 'Morgan') {
            await axios({
                method: 'post',
                url: 'https://project-christmas-backend-al27ly6i0.vercel.app/familyMemberInfo',
                data: {
                    name: 'Morgan'
                }
            }).then((res: any) => {
                setMemberChildren_id(res.data[0]._id);
            });
        } else if(memberChildren === 'Aiden+Chloe') {
            await axios({
                method: 'post',
                url: 'https://project-christmas-backend-al27ly6i0.vercel.app/familyMemberInfo',
                data: {
                    name: 'Aiden'
                }
            }).then((res: any) => {
                setMemberChildren_id(res.data[0]._id);
            });

            await axios({
                method: 'post',
                url: 'https://project-christmas-backend-al27ly6i0.vercel.app/familyMemberInfo',
                data: {
                    name: 'Chloe'
                }
            }).then((res: any) => {
                setMemberChildren2_id(res.data[0]._id);
            });
        } else if(memberChildren === 'Millie+Moses+Timothy') {
            await axios({
                method: 'post',
                url: 'https://project-christmas-backend-al27ly6i0.vercel.app/familyMemberInfo',
                data: {
                    name: 'Millie'
                }
            }).then((res: any) => {
                setMemberChildren_id(res.data[0]._id);
            });

            await axios({
                method: 'post',
                url: 'https://project-christmas-backend-al27ly6i0.vercel.app/familyMemberInfo',
                data: {
                    name: 'Moses'
                }
            }).then((res: any) => {
                setMemberChildren2_id(res.data[0]._id);
            });

            await axios({
                method: 'post',
                url: 'https://project-christmas-backend-al27ly6i0.vercel.app/familyMemberInfo',
                data: {
                    name: 'Timothy'
                }
            }).then((res: any) => {
                setMemberChildren3_id(res.data[0]._id);
            });
        }
    };

    useEffect(() => {
        getMemberIds();
    }, [members]);

    const newEmptyGiftItem = (member_id: any) => {
        axios({
            method: 'put',
            url: 'https://project-christmas-backend-al27ly6i0.vercel.app/addFamilyMemberEmptyGiftIdea',
            data: {memberDocument_id: member_id}
        }).then((res: any) => {
            setRefresh(!refresh);
        });
    };

    console.log(`about to paint Wishlist Section for ${members[0]}[${member0_id}] and ${members[1]}[${member1_id}]`);

    return (
            <div id={'familyMemberContent'} style={{height: '100%'}}>
                {members.map((member, index) => {
                    let childMember_id: any;

                    if(member === 'Millie') {
                        childMember_id = memberChildren_id;
                    }else if(member === 'Aiden') {
                        childMember_id = memberChildren_id;
                    }else if(member === 'Morgan') {
                        childMember_id = memberChildren_id;
                    }else if(member === 'Chloe') {
                        childMember_id = memberChildren2_id;
                    }else if(member === 'Moses') {
                        childMember_id = memberChildren2_id;
                    }else if(member === 'Timothy') {
                        childMember_id = memberChildren3_id;
                    }

                    let member_id: any;

                    if(member === members[0]) {
                        member_id = member0_id;
                    } else if(member === members[1]) {
                        member_id = member1_id;
                    } else if(member === members[2]) {
                        member_id = member2_id;
                    }

                    return (
                        <Row justify={'space-around'} align={'middle'} style={{marginBottom: '5em'}}>
                            <Col span={18}>
                                <WishlistTable member={member} refresh={refresh}/>

                                {member === loggedInMember ||  member.includes(loggedInMember) || memberChildren.includes(member) ?
                                    <Button
                                        onClick={() => newEmptyGiftItem(memberChildren.includes(member) ? childMember_id : member_id)}
                                        type={'dashed'}
                                        block
                                        icon={<PlusSquareOutlined />
                                        }>Add A Row</Button>
                                    : ''}
                            </Col>
                        </Row>
                    )
                })}
            </div>
    )
};

export default WishlistSection;